export default {
    "about": {
        "title": "Sobre Nosotros",
        "left": "Fuente de conocimiento para tu crecimiento profesional",
        "right": "Todos tenemos el poder y el talento para alcanzar grandes metas. Te ayudamos a encontrar la manera de desarrollarte y lograr tus objetivos a través de la profesión, tal y como lo deseas",
        "author": {
            "title": "Sobre la Dra. Lica",
            "btn": "Ver historia completa"
        },
        "breadCrumbs": {
            "home": "Inicio",
            "about": "Sobre Nosotros"
        }
    },
    "basket": {
        "title": "Tu cesta",
        "date": {
            "postcode": "Código postal",
            "title": "Introduzca los datos",
            "name": "Nombre",
            "surname": "Apellidos",
            "phone": "Teléfono",
            "city": "Población",
            "district": "Provincia",
            "houseNumber": "Nº",
            "email": "E-mail",
            "addressInvoice": "Dirección de facturación",
            "country": "País",
            "street": "Calle",
            "flat": "Nº",
            "person": "Persona de contacto",
            "company": "Empresa",
            "companyName": "Nombre de Empresa",
            "fiscalCode": "DNI",
            "tradeNumber": "NIF",
            "companyAddress": "Dirección Empresa",
            "block": "Piso"
        },
        "delivery": {
            "title": "Entrega",
            "courier": "Entrega",
            "shop": "Recoger en tienda",
            "courierDesc": "Entrega por mensajeria",
            "courierDescRo": "Si el pedido supera 500 lei, la entrega es gratis",
            "shopDesc": "Para quienes quieran visitarnos",
            "address": "Dirección",
            "info": " Entrega gratis: Chisinau – para pedidos superiores a 1200 lei; regiones – para pedidos superiores a 1500 lei. En caso contrario, el pago del transporte correrá a cargo del cliente (el coste varía según la localidad y el peso del paquete).",
            "novaPosta": "Entrega gratis: Chisinau – para pedidos superiores a 1200 lei; regiones - para pedidos superiores a 1500 lei.",
            "selectType": {
                "courier": "Mensajería",
                "inOffice": "En oficina Nova Poshta"
            }
        },
        "street": {
            "houseNumber": "Nº.",
            "flat": "Piso"
        },
        "payment": {
            "title": "Métodos de pago",
            "сash": "Efectivo",
            "card": "Tarjeta"
        },
        "confirmare": {
            "title": "Confirmación",
            "comments": "Comentarios",
            "promo": "Código promocional",
            "orderAmount": "Total pedido",
            "delivery": "Entrega",
            "total": "Total",
            "finish": "Terminar pedido",
            "conditions": "Acepto los términos",
            "policy": "Política de privacidad"
        },
        "useCashback": "Usar bonus",
        "noProducts" : {
          "title" : " No hay artículos en el carrito, pero es fácil cambiarlo",
          "btn": "Ir a la tienda"
        }
    },
    "category": {
        "titlePage": "Cursos",
        "home": "Inicio",
        "results": "Resultados",
        "reload": "Cargar más",
        "from": "Desde",
        "advancedFiltering": "Filtros Avanzados"
    },
    "aboutAuthor":{
        "author": {
            "btn": "Ver Historia completa"
        }
    },
    "cardTags":{
        "from": "Desde"
    },
    "contactForm":{
        "form": {
            "title": { "text1": "Cuéntanos tus", "color": " objetivos ", "text2": "" },
            "desc": "Te ayudaremos a elegir el programa perfecto para ti.",
            "consent": { "text1": "Acepto los términos y ", "link": "condiciones ", "text2": "del uso de datos personales" },
            "country": "País",
            "name": "Nombre y Apellidos",
            "phone": "Teléfono",
            "btn": "Dejar una solicitud",
            "message": " La solicitud ha sido enviada exitosamente y nuestro gerente se comunicará con usted en breve."
        }
    },
    "footer":{
            "courses": "Cursos",
			"shop": "Tienda",
			"specialists": "Especialistas",
			"about": "Sobre Nosotros",
			"blog": "Blog",
			"login": "Acceso",
			"registration": "Registro",
			"terms": "Térmios y condiciones",
			"provisions": "Política de privacidad",
			"message": "Dejar un mensaje",
			"desc": " La primera Academia de belleza especializada en cosmetología y medicina estética. Profesionales certificados en la materia te brindarán los mejores materiales para ayudarte a lograr tu objetivo.",
			"policy": "Términos de uso",
            "security": " Política de seguridad"
    },
    "header": {
        "courses": "Cursos",
        "shop": "Tienda",
        "specialists": "Especialistas",
        "about": "Sobre Nosotros",
        "blog": "Blog",
        "promotions": "Promociones",
        "login": "Acceso",
        "registration": "Registro",
        "shopHidden": "solo para usuarios registrados",
        "shopClose": "no disponible en tu región",
        "dashboard": "Mi cuenta"
    },
    "personalSlider":{
        "title": { "text": "Creemos en tu ", "color": "éxito" },
        "btn": "Ver historia completa"
    },
    "course":{
        "btnPath": {
            "home": "Inicio",
            "category": "Cursos"
        },
        "skillTitle": {
            "text": "Ventajas del ",
            "color": "curso"
        },
        "courseTitle": {
            "text": "Curso",
            "color": " Contenido"
        },
        "courseDesc": " Te esperan clases online, trabajos prácticos, talleres y masterclasses.",
        "chapters": "Teoría",
        "modules": "Vídeos prácticos",
        "priceTitle": "Precio",
        "buy": "Comprar curso",
        "subscription": "Revista online",
        "login": "Regístrate ahora",
        "SeeTheProgram": "Contenido del curso",
        "modalBuyCourse": {
            "title": "Comprar curso",
            "name": "Nombre",
            "phone": "Teléfono",
            "modeBuy": "Método de pago",
            "modalBtnBuy": "Comprar",
            "buy": "Tarjeta",
            "conditions": "Aceptar términos y condiciones",
            "terms": "Términos de uso",
            "payment": "Pago del curso"
        },
        "preview": " EL CURSO ESTÁ GRABADO, PUEDES COMENZAR A APRENDER EN CUALQUIER MOMENTO DESPUÉS DE LA COMPRA."
    },
    "courseInner":{
        "btnPath": {
            "home": "Inicio",
            "category": "Cursos"
        },
        "courseMaterials": "Materiales del curso",
        "courseTitle": {
            "text": "Curso",
            "color": " Contenido"
        },
        "courseDesc": " Te esperan clases online, trabajos prácticos, talleres y masterclasses.",
        "chapters": "Teoría",
        "modules": "Videos prácticos"
    },
    "dashboard":{
        "abonament":{
            "title": "Cursos",
			"purchasedCourses": "Mis cursos",
			"viewStatus": {
				"start": "Pendiente",
				"mid": "Incompleto",
				"final": "Completado"
			},
			"btnPlay": "Play"
        },
        "shop":{
            "title": "Historia",
            "titleDesc": "Mis operaciones",
            "message": " Aún no tienes ninguna venta, aquí se mostrará una lista de tus ventas",
            "products": "Productos",
            "cashback": "Reembolso"
        },
        "applications":{
            "title": "Solicitudes",
			"list": "Lista",
			"calendar": "Calendario",
			"confirmation": "Confirmación",
			"table": {
				"title": "Solicitud confirmada",
				"name": "Nombre",
				"phone": "Teléfono",
				"data": "Fecha registro",
				"endData": "Registro confirmado"
			},
			"btn": {
				"title": "Confirmar",
				"done": "Сancelar",
				"addUser": "Añadir registro"
			},
			"emptyMessage": "No tienes solicitudes",
			"msgConfirmate": "No tienes solicitudes confirmadas",
      "warnings": " La fecha y hora seleccionadas ya están ocupadas"
        },
        "mobile": {
            "showMore": "Mostrar más",
            "showLess": "Mostrar menos"
        }
    },
    "faq":{
        "title": {
            "text": "¿Tienes alguna ",
            "color": "pregunta?"
        },
        "desc": {
            "text": "Preguntas frecuentes",
            "color": "Preguntas",
            "text2": " Estaremos cerca de ti durante todo tu proceso de estudio."
        },
        "info": " Para más información contáctenos",
        "btnText": "Dejar solicitud"
    },
    "home":{
        "header": {
            "courses": "Cursos",
            "shop": "Tienda",
            "specialists": "Especialistas",
            "about": "Sobre Nosotros",
            "blog": "Blog",
            "promotions": "Promociones",
            "login": "Acceso",
            "registration": "Registro",
            "shopHidden": "Solo para usuarios registrados",
            "shopClose": "No disponible en tu región"
        },
        "slider": {
    
            "btn": "Seleccionar curso"
        },
        "about": {
            "video": { "title": "Video", "desc": "Cursos teóricos y prácticos" },
            "support": { "title": "Ayuda", "desc": "Asesoramiento de los mejores especialistas" },
            "web": { "title": "WEB", "desc": " Crea tu propia página web con citas online" },
            "results": { "title": "Resultados", "desc": "Certificados profesionales" }
        },
        "support": {
            "question": "¿Necesitas ayuda?",
            "desc": " Siempre estamos listos para ayudarte y responder cualquier pregunta.",
            "btn": "Contáctanos"
        },
        "courses": {
            "title": "Nuestros cursos",
            "left": "Cosmetología",
            "right": "Medicina estética",
            "btn": "Ver todos los cursos",
            "massage": "Masaje",
        },
        "author": {
            "title": "Sobre la Dra.Lica",
            "btn": "Ver historia completa"
        },
        "offers": {
            "title": {
                "txt": "Ofertas",
                "color": "Especiales"
            }
        }
    },
    "login":{
        "title": "Acceso",
        "name": " Acceso ",
        "password": "Contraseña",
        "newpass": "Nueva contraseña",
        "confirmPas": "Confirmar contraseña",
        "login": "Iniciar sesión",
        "registration": "Incríbirse",
        "recoveryPass": "Recuperar",
        "btnRecover": "Recuperar",
        "save": "Guardar",
        "generate": "Generar",
        "badDate": "Datos incorrectos",
        "password2": "La contraseña debe tener 6 caracteres",
        "forgotPass": "¿Has olvidado tu contraseña?",
        "message": "Revisa tu correo"
    },
    "registration":{
        "title": "Registro",
        "name": "Nombre",
        "surname": "Apellidos",
        "phone": "Teléfono",
        "email": "email",
        "password": "Contraseña",
        "password2": "Confirmar contraseña",
        "proffession": {
            "title":"Profesión",
            "cosmetolog": "Esteticista/Enfermera",
            "medic": "Doctor",
            "other": "Para clientes sin certificado de esteticista"
        },
        "errors": {
            "password1" : " La contraseña debe tener 6 caracteres",
            "password2" : "Contraseña errónea"

        },  
        "strongPassword": "Contraseña insegura",
        "messageCertificate": " El nombre y apellido aparecerán en el certificado.",
        "messageBusyUser":"email ya registrado",
        "certificate": "Subir certificado",
        "login": "Acceso",
        "log_in": "Registrarse",
        "politics": "Aceptar términos y condiciones",
        "politics2": "Acepto la política de privacidad",
        "popup": " Su registro está siendo verificado",
        "country": "País",
        "modal": {
            "message": ` ¡Ya estás registrado! ¡Enhorabuena!<br/> 
            Su cuenta estará activa dentro de 24 horas. <br/>
            ¡Como muestra de nuestro agradecimiento te regalamos una lección en vídeo de nuestros cursos! <br/>
            Para comenzar a ver el vídeo, accede a tu cuenta personal <a href='https://lica.doctor/ru/dashboard'>https://lica.doctor/dashboard<a/> <br/>
            ¡Esperamos verte entre nuestros estudiantes!<br/> 
            Para cualquier consulta por favor contacte con nuestros gerentes: info@lica.doctor `,
          "message2": `¡Ya estás registrado! ¡Enhorabuena!<br/>
            Su cuenta estará activa dentro de 24 horas. <br/>
            ¡Esperamos verte entre nuestros estudiantes!<br/>
            Para cualquier consulta por favor contacte con nuestros gerentes: info@lica.doctor.`
        }
    },
    "shop":{
        "links": {
            "home": "Inicio",
            "shop": "Tienda"
        },
        "title": "Tienda",
        "filtreTitle": " Filtrar productos",
        "brand": "Marca",
        "price": "Precio",
        "categorie": "Categoría",
        "sorted": "Gama",
        "result": "Resultados",
        "btnAdd": "Añadir",
        "loadPage": "Cargar mas",
        "specialist": "Especialistas"
    },
    "specialists":{
        "linkHome": "Inicio",
        "title":"Especialistas",
        "btn": "Agendar una cita",
        "btn2": "Tienda",
        "selectTitle": "Elija una fecha para registrarse",
        "price": " Consulta de",
        "reload": "Cargar mas",
        "result": "Resultados",
        "search": "Buscar",
        "address": "Dirección",
        "certificateTitle": {
            "text": "Certificados y ",
            "color": "diplomas"
        }
    },
    "certificates":{
        "title": "Lica.Doctor Beauty Academy Certifica",
        "descTitle": "Certificados recibidos tras visualizar los cursos en la plataforma Lica.Doctor Beauty Academy",
        "title2": "Mis certificados",
        "descTitle2": "Certificados y diplomas personales, que no hagan referencia a la academia",
        "btnAdd": "Añadir certificado",
        "messageCertificate": "No tienes ningún certificado aún",
        "messageCertificateLink": "Ir a la página del curso",
        "warning": "Advertencia",
        "confirmation": "¿Eliminar certificado?",
        "delete": "Eliminar"

    },
    "changePass":{
        "mainTitle": "Cambiar contraseña",
        "oldPass": "Contraseña anterior",
        "newPass": "Nueva contraseña",
        "repeatPass": "Repetir contraseña",
        "save": "Guardar",
        "errMes1": "Las contraseñas no coinciden"
    },
    "courses":{
        "noAvailable": "No tienes ningún curso aún puedes",
        "noAvailableLink": "comprar el primero.",
        "free": "Gratis",
        "advantage": "Convierte tus ideas en realidad",
        "price": "0 MDL",
        "perMonth": "/mes",
        "current": "Plan actual",
        "advantage1" : "Acceso a visualización de vídeos 10 horas/mes",
        "advantage2": "Acceso limitado a los cursos",
        "advantage3": "Horarios de clases y cursos",
        "type": "Pro",
        "advantage4": "Mejor para individual",
        "price2": "300 MDL",
        "buy": "Comprar Pro",
        "advantage5": " Visualización de videos 36 horas/mes",
        "advantage6": "Acceso ilimitado a los cursos",
        "work": " Materiales de trabajo",
        "resource": "Recursos",
        "type2": "Pro+",
        "advantage8": "Para verdaderos profesionales",
        "price3": "1 200 MDL",
        "buy2": "Comprar Pro+",
        "advantage10": "Acceso ilimitado a visualización de videos",
        "advice": "Consejos prácticos de profesionales"
    },
    "myPage":{
        "message": "Rellena el campo",
        "title": "Mi cuenta",
        "certificates": "Mis certificados",
        "certificatesDesc": "Lista de certificados mostrados",
        "services": "Mis servicios",
        "btnAdd": "Añadir",
        "btnSave": "Guardar",
        "personalLink": "Link personal",
        "placeholder":{
            "name": "Nombre / Apellidos",
            "speciality": "Especialidad",
            "price": "Precio",
            "address": "Dirección"
        }
    },
    "orders":{
        "title": "Historial",
        "titleDesc": "Todas las operaciones",
        "message": " Aún no tienes ningún pago, aquí se mostrará una lista de tus pagos"
    },
    "protocols":{
        "message": " Aún no tienes ningún protocolo, puedes obtener los protocolos al comprar el curso"
    },
    "requests":{
        "busy": "Ocupado",
        "daysOfWeek": ["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"]
    },
    "article":{
        "home": "Inicio",
        "blog": "Blog",
        "article": "Articulo",
        "back": "atras",
        "readMore": "Leer más"
    },
    "checkout":{
        "message": "¡Su pedido se ha completado con éxito!",
        "messageDesc": " En breve recibirás un email con los detalles de tu pedido"
    },
    "product":{
        "home": "Inicio",
        "shop": "Tienda",
        "add": "Añadir al carrito",
        "added": "Añadido",
        "sliderTitle": ["Productos","similares"],
        "returnPolicy": "Devoluciones: Los cosméticos con embalaje y/o lámina sellada solo se pueden devolver si el embalaje y/o la lámina están intactos.",
        "inStock": "Sin stock"
    },
    "promotion":{
        "home": "Inicio",    
        "breadcrumb1": "Promociones",
        "breadcrumb2": "Promoción",
        "back": "Atrás"
    },
    "promotions":{
        "readMore": "Leer más",
        "home":"Inicio",
        "breadcrumb1":"Promociones"
    },
    "usefulInfo":{
        "home": "Inicio",
        "blog": "Información útil",
        "article": "Articulo",
        "back": "Atrás"
    },
    "appoitment":{
        "title": "Inscribirse",
        "namePhone": "Fecha y hora",
        "comment": "Comentario",
        "name": "Nombre",
        "phone": "Teléfono",
        "btnModal": "Enviado",
        "message": "¡Su solicitud ha sido enviada!"
    },
    "calendar":{
        "title": "Editar la solicitud",
        "namePhone": "Nombre y teléfono",
        "name": "Nombre",
        "phone": "Teléfono",
        "timeDate": "Fecha y hora",
        "comment": "Comentario",
        "btn": "Guardar"
    },
    "confirmation": {
        "text": "Guardado correctamente"
    },
    "politics":{
        "message": "Utilizamos cookies en nuestro sitio web para ofrecerle la mejor manera de personalizar sus preferencias. Al hacer clic en el botón \"Acepto\", acepta el uso de cookies. Vaya a Configuración de cookies para obtener más información",
        "btn": "Acepto",
        "link": "Política de seguridad"
    },
    "productSelect": "Buscar producto",
    "certificateUpload": "Este certificado confirma sus calificaciones",
    "warnings": "Advertencias",
    "breadCrumbs": {
      "back": "Atrás"
    },
    coursesUpdatesModal: {
        title: "Tienes nuevos videos/protocolos en los cursos",
        content: "Se ha añadido un nuevo vídeo/protocolo al curso. <b>{courses}</b>",
        button: "Ir a cursos",
    }
}
