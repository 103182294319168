export default {
  "about": {
      "title": "Despre noi",
      "left": "Sursa de cunoștințe pentru creșterea ta profesională",
      "right": "Fiecare are puterea și talentul pentru a atinge obiective grozave. Vă ajutăm să găsiți o cale de dezvoltare și să vă realizați prin profesie - așa cum doriți",
      "author": {
          "title": "Despre autor",
          "btn": "Vezi toată istoria"
      },
      "breadCrumbs": {
          "home": "Acasă",
          "about": "Despre noi"
      }
  },
  "basket": {
      "title": "Coșul tău",
      "date": {
          "postcode": "Cod Poștal",
          "title": "Completeaza datele",
          "name": "Nume",
          "surname": "Prenume",
          "phone": "Telefon",
          "city": "Oraș",
          "district": "Județ",
          "houseNumber": "Numărul casei",
          "email": "E-mail",
          "addressInvoice": "Address invoice",
          "country": "Țara",
          "street": "Strada",
          "flat": "Apartament",
          "person": "Persoană fizică",
          "company": "Persoană juridică",
          "companyName": "Numele companiei",
          "fiscalCode": "CIF firmei",
          "tradeNumber": "RC Firmei",
          "companyAddress": "Adresa firmei",
          "block": "Bloc"
      },
      "delivery": {
          "title": "Livrare",
          "courier": "Livrare",
          "shop": "Ridicare din magazin",
          "courierDesc": "Livrarea în departament sau prin curier",
          "courierDescRo": "Daca valoarea comenzii depaseste 500 de lei, livrarea este GRATUITA",
          "shopDesc": "Pentru cei care doresc sa ne viziteze",
          "address": "Adresa deplină",
          "info": " Livrare gratuită: Chișinău - pentru comenzi mai mari de 1200 lei; regiuni - pentru comenzi mai mari de 1500 lei. În caz contrar, plata transportului va fi suportată de client(costul difera în funcție de localitate și greutatea coletului).",
          "infoRo": "Pentru comenzile mai mari de 500 lei - transportul este gratuit. În caz contrar, plata transportului va fi suportată de client și calculată de Fan Courier (costul difera în funcție de localitate).",
          "novaPosta": "Livrare gratuită: Chișinău - pentru comenzi mai mari de 1200 lei; regiuni - pentru comenzi mai mari de 1500 lei.",
          "selectType": {
              "courier": "Curier",
              "inOffice": "Din oficiu Nova Poshta"
          }
      },
      "street": {
          "houseNumber": "Nr.",
          "flat": "ap."
      },
      "payment": {
          "title": "Modalitati de plata",
          "сash": "Cash",
          "card": "Card bancar"
      },
      "confirmare": {
          "title": "Confirmare",
          "comments": "Comentarii",
          "promo": "Cod promoțional",
          "orderAmount": "Suma comenzii",
          "delivery": "Livrare",
          "total": "Total",
          "finish": "Finalizeaza comanda",
          "conditions": "Sunt de acord cu termenii",
          "policy": "Politica de confidețialitate"
      },
      "useCashback": "Utilizați bonusuri",
      "noProducts" : {
        "title" : "În coș nu este nimic, dar aceasta se poate ușor de corectat",
        "btn": "Treci în magazin"
      }
  },
  "category": {
      "titlePage": "Cursuri",
      "home": "Acasa",
      "results": "rezultate",
      "reload": "Mai încarcă",
      "from": "de la",
      "advancedFiltering": "Filtrare avansată"
  },
  "aboutAuthor":{
      "author": {
          "btn": "Vezi toată istoria"
      }
  },
  "cardTags":{
      "from": "de la"
  },
  "contactForm":{
      "form": {
          "title": { "text1": "Povestește-ne despre", "color": " scopurile ", "text2": "tale" },
          "desc": "Vă vom ajuta să alegeți programul potrivit pentru Dumneavoastră.",
          "consent": { "text1": "Sunt de acord cu", "link": "Termenii și condițiile ", "text2": "de prelucrare a informației cu caracter personal" },
          "country": "Ţară",
          "name": "Numele",
          "phone": "Telefon",
          "btn": "Lasă o cerere",
          "message": "Aplicația a fost trimisă cu succes, iar managerul nostru vă va contacta în scurt timp"
      }
  },
  "footer":{
          "courses": "Cursuri",
    "shop": "Magazin",
    "specialists": "Specialiști",
    "about": "Despre noi",
    "blog": "Blog",
    "login": "Crează un cont",
    "registration": "Înregistrare",
    "terms": "Termeni și condiții",
    "provisions": "Politica de confidețialitate",
    "message": "Lasă un mesaj",
    "desc": "Prima Academie de frumusețe, specializată în cosmetologie și medicină estetică. Specialiști certificați în sfera vă vor oferi cele mai bune materiale pentru a vă ajuta să vă atingeți scopul.",
    "policy": "Reguli de utilizare",
          "security": "Politica de securitate"
  },
      "header": {
          "courses": "Cursuri",
          "shop": "Magazin",
          "specialists": "Specialiști",
          "about": "Despre noi",
          "blog": "Blog",
          "promotions": "Promoții",
          "login": "Autentificare",
          "registration": "Înregistrare",
          "shopHidden": "numai pentru utilizatorii înregistrați",
          "shopClose": "nu este disponibil în regiunea dvs",
          "dashboard": "Cont Personal"
      },
  "personalSlider":{
      "title": { "text": "Credem în succesul ", "color": "fiecăruia" },
      "btn": "Vezi toată istoria"
  },
  "course":{
      "btnPath": {
          "home": "Acasa",
          "category": "Cursuri"
      },
      "skillTitle": {
          "text": "Beneficiile",
          "color": "cursului"
      },
      "courseTitle": {
          "text": "Conținutul",
          "color": "cursului"
      },
      "courseDesc": "Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri",
      "chapters": "teoretic",
      "modules": "videoclipuri practice",
      "priceTitle": "Costul cursului",
      "buy": "Cumpără curs",
      "subscription": "Magazin online",
      "login": "Înscrie-te acum",
      "SeeTheProgram": "Continutul cursului",
      "modalBuyCourse": {
          "title": "Cumpără curs",
          "name": "Numele",
          "phone": "Telefon",
          "modeBuy": "Modalități de plată",
          "modalBtnBuy": "Cumpără",
          "buy": "Card bancar",
          "conditions": "Accept termenile si conditiile",
          "terms": "Reguli de utilizare",
          "payment": "Plata cursului"
      },
      "preview": "CURSUL VIDEO ESTE ÎNREGISTRAT, PUTEȚI ÎNCEPE SĂ ÎNVĂȚAȚI ÎN ORICE MOMENT DUPĂ  CE ÎL PROCURAȚI"
  },
  "courseInner":{
      "btnPath": {
          "home": "Acasa",
          "category": "Cursuri"
      },
      "courseMaterials": "Materialele cursului",
      "courseTitle": {
          "text": "Conținutul",
          "color": " cursului"
      },
      "courseDesc": "Vă așteaptă lecții online, sarcini practice, workshop-uri și masterclass-uri",
      "chapters": "teoretic",
      "modules": "videoclipuri practice"
  },
  "dashboard":{
      "abonament":{
          "title": "Cursuri",
    "purchasedCourses": "Cursuri procurate",
    "viewStatus": {
      "start": "Neînceput",
      "mid": "Nefinalizat",
      "final": "Finalizat"
    },
    "btnPlay": "Continuă"
      },
      "shop":{
          "title": "Istoric",
          "titleDesc": "Toate operațiile",
          "message": "Nu aveți încă vânzări, o listă a vânzărilor dvs. va fi afișată aici",
          "products": "Produse",
          "cashback": "Cashback"
      },
      "applications":{
          "title": "Cereri",
    "list": "Listă",
    "calendar": "Calendar",
    "confirmation": "Autoconfirmare",
    "table": {
      "title": "Cereri confirmate",
      "name": "NUMELE",
      "phone": "NUMĂR TELEFON",
      "data": "DATA ÎNSCRIERII",
      "endData": "EFECTUAREA ÎNSCRIERII"
    },
    "btn": {
      "title": "Confirmă",
      "done": "Anulează",
      "addUser": "Adaugă înscriere"
    },
    "emptyMessage": "Nu aveti cereri noi",
    "msgConfirmate": "Dvs nu aveti cereri confirmate",
    "warnings": "Data și ora selectată sunt deja ocupate"
    },
    "mobile": {
      "showMore": "Mai arată",
      "showLess": "Colaps"
    },
  },
  "faq":{
      "title": {
          "text": "Aveți ",
          "color": "neclarități?"
      },
      "desc": {
          "text": "Aici puteți găsi răspunsuri la cele mai frecvente întrebări ",
          "color": "sau ne puteți contacta.",
          "text2": "Noi vom fi alături de Dumneavoastră pe tot parcursul studiilor."
      },
      "info": "Pentru mai multe informații ne puteți contacta",
      "btnText": "Lasă o cerere"
  },
  "home":{
      "header": {
          "courses": "Cursuri",
          "shop": "Magazin",
          "specialists": "Specialiști",
          "about": "Despre noi",
          "blog": "Blog",
          "promotions": "Promoții",
          "login": "Autentificare",
          "registration": "Înregistrare",
          "shopHidden": "numai pentru utilizatorii înregistrați",
          "shopClose": "nu este disponibil în regiunea dvs."
      },
      "slider": {
  
          "btn": "Alege un curs acum"
      },
      "about": {
          "video": { "title": "Video", "desc": "Lecții video și webinare" },
          "support": { "title": "Support", "desc": "Sfaturile celor mai buni specialiști" },
          "web": { "title": "WEB", "desc": "Beneficiezi de pagina web personală cu posibilitatea de înscriere online" },
          "results": { "title": "Rezultat", "desc": "Certificate de calificare" }
      },
      "support": {
          "question": "Ai nevoie de ajutor?",
          "desc": "Vom fi bucuroși să vă răspundem la oricare întrebări și să vă îndrumăm",
          "btn": "Contactează acum"
      },
      "courses": {
          "title": "Cursurile noastre",
          "left": "Cosmetologie",
          "right": "Medicină estetică",
          "btn": "Vezi toate cursurile",
          "massage": "Masaj",
      },
      "author": {
          "title": "Despre autor",
          "btn": "Vezi toată istoria"
      },
      "offers": {
          "title": {
              "txt": "Oferte",
              "color": "speciale"
          }
      }
  },
  "login":{
      "title": "Login",
      "name": "Login",
      "password": "Parolă",
      "confirmPas": "Confirmă parola",
      "newpass": "Parolă nouă",
      "login": "Intră în cabinet",
      "registration": "Înregistrare",
      "recoveryPass": "Recuperare",
      "btnRecover": "Recuperare",
      "save": "Salvează",
      "generate": "Generează",
      "badDate": "Date nevalide",
      "password2": "Parola trebuie sa contina 6 simboluri",
      "forgotPass": "Ați uitat parola ?",
      "message": "Verificați vă rog poșta electronică"
  },
  "registration":{
      "title": "Înregistrare",
      "name": "Prenume",
      "surname": "Nume",
      "phone": "Telefon",
      "email": "email",
      "password": "Parola",
      "password2": "Confirmă parola",
      "proffession": {
          "title":"Specialitate",
          "cosmetolog": "Cosmetolog (asistent medical)",
          "medic": "Medic",
          "other": "Pentru clienți fără diploma de cosmetician"
      },
      "errors": {
          "password1" : "Parola trebuie sa contina 6 simboluri",
          "password2" : "Parolele nu coincid"

      },
      "strongPassword": "Parola nu este sigură",
      "messageCertificate": "Numele și prenumele se vor afișa în certificat",
      "messageBusyUser":"email este ocupat",
      "certificate": "încărcă Certificatul",
      "login": "Intră",
      "log_in": "ÎNREGISTREAZĂ-TE",
      "politics": "Accept termеnile si conditiile",
      "politics2": "Accept politica de confidențialitate",
      "popup": "Înregistrarea dvs. este verificată",
      "country": "Țară",
      "modal": {
        "message": `Ați trecut etapa înregistrării! Felicitări! <br/> 
          Contul Dvs. va fi activat pe parcurs de 24 de ore. <br/> 
          In semn de Mulțumire vă oferim un video din cursurile noastre! <br/> 
          În continuare, pentru a începe studierea, e necesar să intrați în cabinetul personal
          <a href='https://lica.doctor/ru/dashboard'>https://lica.doctor/dashboard<a/> <br/>
          Vă așteptăm cu nerăbdare pe site-ul nostru! <br/> 
          Pentru orice întrebări, puteți contacta managerii noștri pe adresa: info@lica.doctor.`,
        "message2": `Ați trecut etapa înregistrării! Felicitări! <br/> 
          Contul Dvs. va fi activat pe parcurs de 24 de ore. <br/> 
          Vă așteptăm cu nerăbdare pe site-ul nostru! <br/> 
          Pentru orice întrebări, puteți contacta managerii noștri pe adresa: info@lica.doctor.`
      }
  },
  "shop":{
      "links": {
          "home": "Acasă",
          "shop": "Magazin online"
      },
      "title": "Magazin online",
      "filtreTitle": "FILTRARE PRODUSE",
      "brand": "Brand",
      "price": "Preț",
      "categorie": "Categorie",
      "sorted": "Sortare",
      "result": "rezultate",
      "btnAdd": "Cumpăr",
      "loadPage": "Mai încarcă",
      "specialist": "Specialiști"
  },
  "specialists":{
      "linkHome": "Acasa",
      "title":"Specialiști",
      "btn": "Programează-te",
      "btn2": "Magazin",
      "selectTitle": "Alegeți o dată pentru a vă înscrie",
      "price": "Consultație",
      "reload": "Mai încarcă",
      "result": "rezultate",
      "search": "Caută",
      "address": "Locație",
      "certificateTitle": {
          "text": "Certificate și ",
          "color": "diplome"
      }
  },
  "certificates":{
      "title": "Certificate academie Doctor Lica",
      "descTitle": "Lista certificatelor primite în urma trecerii cursurilor pe platforma academiei Doctor Lica",
      "title2": "Certificate proprii",
      "descTitle2": "Lista certificatelor și distincțiilor proprii, care nu se referă la academie",
      "btnAdd": "Adaugă certificat",
      "messageCertificate": "Nu aveți încă niciun certificat",
      "messageCertificateLink": "Accesați pagina cursuri",
      "warning": "Atenție",
      "confirmation": "Șterge Certificatul ?",
      "delete": "Șterge"
  },
  "changePass":{
      "mainTitle": "Schimbă-ți parola",
      "oldPass": "Parola veche",
      "newPass": "Parolă nouă",
      "repeatPass": "Repetă parola",
      "save": "Salvează",
      "errMes1": "Parola nu se potrivește"
  },
  "courses":{
      "noAvailable": "Nu aveți încă cursuri, îl puteți",
      "noAvailableLink": "cumpăra pe primul.",
      "free": "Gratuit",
      "advantage": "Aduc ideile tale către viață",
      "price": "0 MDL",
      "perMonth": "/lună",
      "current": "Plan curent",
      "advantage1" : "Acces vizionare video 10 ore/lună",
      "advantage2": "Acces limitat cursuri",
      "advantage3": "Programa de lecții și cursuri",
      "type": "Pro",
      "advantage4": "Cel mai bun pentru individuali",
      "price2": "300 MDL",
      "buy": "Cumpără Pro",
      "advantage5": "Acces vizionare video 36 ore/lună",
      "advantage6": "Acces nelimitat cursuri",
      "work": "Materiale de lucru",
      "resource": "Resurse",
      "type2": "Pro+",
      "advantage8": "Pentru profesionali adevărați",
      "price3": "1 200 MDL",
      "buy2": "Cumpără Pro+",
      "advantage10": "Acces vizionare video nelimitat",
      "advice": "Sfaturi practice de la curatori"
  },
  "myPage":{
      "message": "Completați câmpul",
      "title": "Pagina mea",
      "certificates": "Certificate proprii",
      "certificatesDesc": "Lista certificatelor afișate",
      "services": "Serviciile mele",
      "btnAdd": "Adăuga",
      "btnSave": "Salvează",
      "personalLink": "Linkul personal",
      "placeholder":{
          "name": "Nume / Prenume",
          "speciality": "Specialitate",
          "price": "Preț",
          "address": "Adresă"
      }
  },
  "orders":{
      "title": "Istoric",
      "titleDesc": "Toate operațiile",
      "message": "Nu aveți încă nicio plată, o listă a plăților dvs. va fi afișată aici"
  },
  "protocols":{
      "message": "Nu aveți încă protocoale, puteți obține protocoalele atunci când achiziționați cursul"
  },
  "requests":{
      "busy": "Ocupat",
      "daysOfWeek": ["Lu", "Ma", "Mi", "Jo", "Vi", "Sb", "Du"]
  },
  "article":{
      "home": "Acasă",
      "blog": "Blog",
      "article": "Articol",
      "back": "înapoi",
      "readMore": "Mai departe"
  },
  "checkout":{
      "message": "Comanda a fost plasată cu succes!",
      "messageDesc": "În curând veți primi un email cu detaliile comenzii dumneavoastră"
  },
  "product":{
      "home": "Acasa",
      "shop": "Magazin online",
      "add": "Adaugă în coș",
      "added": "Adăugat",
      "sliderTitle": ["Produse","similare"],
      "returnPolicy": "Retur: Produsele cosmetice care prezintă ambalaj și/sau folie sigilate se pot returna doar dacă au ambalajul și/sau folia intacte",
      "inStock": "Nu este în stock"
  },
  "promotion":{
      "home": "Acasa",    
      "breadcrumb1": "Stoc",
      "breadcrumb2": "Promovare",
      "back": "înapoi"
  },
  "promotions":{
      "readMore": "Mai departe",
      "home":"Acasă",
      "breadcrumb1":"Promoții"
  },
  "usefulInfo":{
      "home": "Acasă",
      "blog": "Informații utile",
      "article": "Articol",
      "back": "înapoi"
  },
  "appoitment":{
      "title": "Înscrie-te",
      "namePhone": "Numele și numerele de telefon",
      "comment": "Comentariu",
      "name": "Numele",
      "phone": "Telefone",
      "btnModal": "Salvează",
      "message": "Solicitarea dvs a fost transmisa!"
  },
  "calendar":{
      "title": "Redacteaza înscriere",
      "namePhone": "Numele și numerele de telefon",
      "name": "Numele",
      "phone": "Telefon",
      "timeDate": "Data și ora",
      "comment": "Comentariu",
      "btn": "Salvează"
  },
  "confirmation": {
      "text": "Salvare reușită"
  },
  "politics":{
      "message": "Folosim cookie-uri pe site-ul nostru web pentru a vă oferi cea mai relevantă experiență prin colectarea preferințelor Dvs și repetarea vizitelor. Accesând butonul ”Accept” Dvs dați acord la utilizarea cookie-urilor. Accesați Setările Cookie-urilor pentru a afla mai multe destpre cookie-urile utilizate pe site.",
      "btn": "Accept",
      "link": "Politica cookies"
  },
  "productSelect": "Caută produs",
  "certificateUpload": "Acest certificat confirma competentă dumneavoastră.",
  "warnings": "Avertismente",
  "breadCrumbs": {
    "back": "înapoi"
  },
  coursesUpdatesModal: {
    title: "Dvs aveți actualizări la cursuri",
    content: "Un video, protocol au fost adăugate la curs <b>{courses}</b>",
    button: "Treci la cursuri"
  }
}
