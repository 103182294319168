<template>
<div class="dashboard__col">
  <!-- <h1>{{ getTitle }}</h1> -->
  <div class="transactions__history">
    <h2>{{$t('orders.title')}}</h2>
    <div class="search__box" v-if="false">
      <select>
        <option>{{$t('orders.titleDesc')}}</option>
      </select>
      <input type="text" placeholder="Caută">
    </div>
    <div class="card" v-for="(item, index) in reversOrders" :key="index" :class="item.confirmed ? 'purchase' : 'order'">
      <div class="type">
        <div class="icon">
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="item.paid_by_card">
            <path d="M2.76512 12H13.2375C14.6334 12 15.3346 11.306 15.3346 9.91794V2.75544C15.3346 1.36741 14.6334 0.666656 13.2375 0.666656H2.76512C1.36924 0.666656 0.667969 1.36741 0.667969 2.75544V9.91794C0.667969 11.306 1.36924 12 2.76512 12ZM1.74326 2.81608C1.74326 2.11533 2.11727 1.75148 2.78515 1.75148H13.2241C13.8853 1.75148 14.2593 2.11533 14.2593 2.81608V3.38207H1.74326V2.81608ZM2.78515 10.9152C2.11727 10.9152 1.74326 10.5581 1.74326 9.8573V4.9116H14.2593V9.8573C14.2593 10.5581 13.8853 10.9152 13.2241 10.9152H2.78515ZM3.55321 8.92072H5.18952C5.58357 8.92072 5.84405 8.65794 5.84405 8.27387V7.02734C5.84405 6.65001 5.58357 6.38049 5.18952 6.38049H3.55321C3.15916 6.38049 2.89869 6.65001 2.89869 7.02734V8.27387C2.89869 8.65794 3.15916 8.92072 3.55321 8.92072Z" fill="white"/>
          </svg>
          <img v-else src="/img/cash.svg" alt="" width="25px">
        </div>
        <span>{{ item.title }}</span>
      </div>
      <div class="details">
        <div class="amount">- {{ item.amount }}</div>
        <div class="time">
          <span class="date">{{item.date}}</span>
          <span>{{item.time}}</span>
        </div>
      </div>
    </div>
    <div v-if="!orders.length" style="width:100%;">
      <h3 style="width:100%; margin-top: 50px; text-align:center;">{{$t('orders.message')}}</h3>
      <svg width="80px" height="80px" class="piggy-bank">
        <use xlink:href="/icons.svg#piggy-bank"></use>
      </svg>
    </div>

  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  beforeMount(){
    this.getOrders();
  },
  data(){
    return {
      orders: [],
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user'
    }),
    getTitle(){  
      return this.langStorage == 'ro' ? 'Comenzile mele' : this.langStorage == 'ru' ? 'Мои заказы' : 'My orders';
    },
    reversOrders(){
      return this.orders.reverse();
    }
  },
  methods: {
    async getOrders(){
      try {
        const result = await fetch(`${domain}/accounts/payment-history/`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        }); 
        let data = await result.json();

        this.orders = data.map(e => {
          let d = new Date(e.date_creation);
          let h = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
          let m = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
          let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
          let month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
          return {
            ...e,
            time: `${h}:${m}`,
            date: `${day}.${month}.${d.getFullYear()}`
          }
        });
      }
      catch(err){
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss">
  body.night {
    .dashboard__col {
      .transactions__history {
        h3 {
          color: white;
        }
      }
    }
  }
  .dashboard__col {
    .piggy-bank {
      display: block;
      margin: auto;
      fill: #89227F;
    }
  }
  .transactions__history{
    margin-top: 16px;
  }
</style>
