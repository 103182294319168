<template>
  <div class="dashboard__col protocols">
    <!-- <h1>{{ getTitle }}</h1> -->
    <div class="orders__col">
    <div class="requests__table">

      <div class="t__top">
        <!-- <div class="name">{{ getTitle }}</div> -->
      </div>
      <!-- <img src="/img/spiner.gif" alt="" style="width: 100px" v-show="!protocols.length"> -->
      <div class="rq__card" v-for="(item) in protocols" :key="item.id" @click="openWindow(item.file)">
        <div class="name">{{item.title}}</div>
      </div>
      <h3 style="width:100%; margin-top: 50px; text-align:center;" v-if="!protocols.length">{{$t('protocols.message')}}</h3>
    </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  async beforeMount(){
   this.protocols = await this.getProtolocs();
  },
  data(){
    return {
      protocols: [],
    }
  },
  watch: {
    async langStorage(){
      this.protocols = await this.getProtolocs();
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user'
    }),
    getTitle(){  
      return this.langStorage == 'ro' ? 'Protocoale' : this.langStorage == 'ru' ? 'Протоколы' : 'Protocols';
    }
  },
  methods: {
    async getProtolocs(){
      try {
        const result = await fetch(`${domain}/${this.langStorage}/courses/protocols/`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        const protocolos = await result.json();

        return protocolos
      }
      catch(err){
        console.error(err);
      }
    },
    openWindow(url){
      window.open(url, '_blank');
      // window.focus();
    }
  }
}
</script>

<style lang="scss">

.protocols {
  margin-top: 16px;
  .rq__card {
    cursor: pointer;
    transition: 0.3s;
    border-radius: 10px;
    .name {
      width: 100% !important;
      font-weight: 600;
      padding-left: 20px;

    }
    &:hover {
      background-color: #891E82;
      .name {
        color: white !important;
      }
    } 
  }
}
@media(max-width:1200px) and (min-width: 430px) {
  .dashboard__col .orders__col .order .order__num {
    margin-right: 100px !important;
  }
}
</style>