export default {
    "about": {
        "title": "About",
        "left": "Source of knowledge for your professional growth",
        "right": "Everyone has the power and talent to achieve great goals. We help you find a way to develop and achieve through the profession - as you wish",
        "author": {
            "title": "About the author",
            "btn": "View full story"
        },
        "breadCrumbs": {
            "home": "Home",
            "about": "About"
        }
    },
    "basket": {
        "title": "Your basket",
        "date": {
            "postcode": "Post Code",
            "title": "Enter the data",
            "name": "Name",
            "surname": "Surname",
            "phone": "Phone",
            "city": "City",
            "district": "District",
            "houseNumber": "House number",
            "email": "E-mail",
            "addressInvoice": "Address invoice",
            "country": "Country",
            "street": "Street",
            "flat": "Flat",
            "person": "Person",
            "company": "Company",
            "companyName": "Company Name",
            "fiscalCode": "Fiscal identification code",
            "tradeNumber": "Trade register number",
            "companyAddress": "Company address",
            "block": "Block"
        },
        "delivery": {
            "title": "Delivery",
            "courier": "Delivery",
            "shop": "From the store",
            "courierDesc": "Delivery in the department or by courier",
            "courierDescRo": "If the order value exceeds 500 lei, the delivery is free",
            "shopDesc": "For those who wish to visit us",
            "address": "Address",
            "info": " Free delivery: Chisinau - for orders over 1200 lei; regions - for orders over 1500 lei.Otherwise, the transport payment will be borne by the customer(the cost differs depending on the locality and the weight of the package).",
            "novaPosta": "Free delivery: Chisinau - for orders over 1200 lei; regions - for orders over 1500 lei.",
            "selectType": {
                "courier": "Courier",
                "inOffice": "In office Nova Poshta"
            }
        },
        "street": {
            "houseNumber": "Nr h.",
            "flat": "flat"
        },
        "payment": {
            "title": "Ways of payment",
            "сash": "Cash",
            "card": "Bank card"
        },
        "confirmare": {
            "title": "Confirmation",
            "comments": "Comments",
            "promo": "Promo Code",
            "orderAmount": "Order amount",
            "delivery": "Delivery",
            "total": "Total",
            "finish": "Finish order",
            "conditions": "I agree with the terms",
            "policy": "Privacy policy"
        },
        "useCashback": "Use bonuses",
        "noProducts" : {
          "title" : "There are no items in the cart, but it's easy to change",
          "btn": "Go to the shop"
        }
    },
    "category": {
        "titlePage": "Courses",
        "home": "Home",
        "results": "results",
        "reload": "Load more",
        "from": "from",
        "advancedFiltering": "Advanced filtering"
    },
    "aboutAuthor":{
        "author": {
            "btn": "View full story"
        }
    },
    "cardTags":{
        "from": "from"
    },
    "contactForm":{
        "form": {
            "title": { "text1": "Tell us about your", "color": " goals ", "text2": "" },
            "desc": "We will help you choose the perfect program for you.",
            "consent": { "text1": "I agree with terms and ", "link": "conditions ", "text2": "of using personal data" },
            "country": "Сountry",
            "name": "Name and surname",
            "phone": "Phone",
            "btn": "Leave a request",
            "message": "The application has been successfully sent, and our manager will contact you shortly"
        }
    },
    "footer":{
            "courses": "Сourses",
			"shop": "Shop",
			"specialists": "Specialists",
			"about": "About",
			"blog": "Blog",
			"login": "Login",
			"registration": "Registration",
			"terms": "Terms and Conditions",
			"provisions": "Privacy policy",
			"message": "Leave a message",
			"desc": "The first beauty Academy specializing in cosmetology and aesthetic medicine. Certified professionals in the field will provide you with the best materials to help you achieve your goal.",
			"policy": "Terms of use",
            "security": "Security policy"
    },
        "header": {
            "courses": "Сourses",
            "shop": "Shop",
            "specialists": "Specialists",
            "about": "About",
            "blog": "Blog",
            "promotions": "Promotions",
            "login": "Login",
            "registration": "Registration",
            "shopHidden": "for registered users only",
            "shopClose": "not available in your region",
            "dashboard": "Personal account"
        },
    "personalSlider":{
        "title": { "text": "We believe in your ", "color": "success" },
        "btn": "View full story"
    },
    "course":{
        "btnPath": {
            "home": "Home",
            "category": "Courses"
        },
        "skillTitle": {
            "text": "Аdvantages of ",
            "color": "the course"
        },
        "courseTitle": {
            "text": "Course",
            "color": " content"
        },
        "courseDesc": "Online lectures, practical assignments, workshops and master classes are waiting for you",
        "chapters": "theoretical",
        "modules": "practical videos",
        "priceTitle": "Price",
        "buy": "Buy a course",
        "subscription": "Online magazine",
        "login": "Sign up now",
        "SeeTheProgram": "Course content",
        "modalBuyCourse": {
            "title": "Buy course",
            "name": "Name",
            "phone": "Phone",
            "modeBuy": "Pay mode",
            "modalBtnBuy": "Buy",
            "buy": "Card bancar",
            "conditions": "Accept term and conditions",
            "terms": "Terms of use",
            "payment": "Course payment"
        },
        "preview": "THE VIDEO COURSE IS RECORDED, YOU CAN START LEARNING AT ANY TIME AFTER PURCHASE."
    },
    "courseInner":{
        "btnPath": {
            "home": "Home",
            "category": "Courses"
        },
        "courseMaterials": "Course materials",
        "courseTitle": {
            "text": "Course",
            "color": " content"
        },
        "courseDesc": "Online lectures, practical assignments, workshops and master classes are waiting for you",
        "chapters": "theoretical",
        "modules": "practical videos"
    },
    "dashboard":{
        "abonament":{
            "title": "Courses",
			"purchasedCourses": "Purchased courses",
			"viewStatus": {
				"start": "Unseen",
				"mid": "Incomplete",
				"final": "Completed"
			},
			"btnPlay": "Play"
        },
        "shop":{
            "title": "History",
            "titleDesc": "All operations",
            "message": "You don't have any sales yet, a list of your sales will be displayed here",
            "products": "Products",
            "cashback": "Cashback"
        },
        "applications":{
            "title": "Requests",
			"list": "List",
			"calendar": "Calendar",
			"confirmation": "Autoconfirmare",
			"table": {
				"title": "Confirmed request",
				"name": "Name",
				"phone": "Phone number",
				"data": "Registration Date",
				"endData": "Registration confirmed"
			},
			"btn": {
				"title": "Confirm",
				"done": "Сancel",
				"addUser": "Add signup"
			},
			"emptyMessage": "You have no new requests",
			"msgConfirmate": "You have no confirmed requests",
      "warnings": "The selected date and time are already occupied"
        },
        "mobile": {
            "showMore": "Show more",
            "showLess": "Show less"
        }
    },
    "faq":{
        "title": {
            "text": "Do you have any ",
            "color": "questions?"
        },
        "desc": {
            "text": "Here you can find answers to frequently asked ",
            "color": "questions",
            "text2": "We will be near you throughout your studying process"
        },
        "info": "For further information contact us",
        "btnText": "Leave a request"
    },
    "home":{
        "header": {
            "courses": "Сourses",
            "shop": "Shop",
            "specialists": "Specialists",
            "about": "About",
            "blog": "Blog",
            "promotions": "Promotions",
            "login": "Login",
            "registration": "Registration",
            "shopHidden": "for registered users only",
            "shopClose": "not available in your region"
        },
        "slider": {
    
            "btn": "Select course"
        },
        "about": {
            "video": { "title": "Video", "desc": "Theoretical and practical courses" },
            "support": { "title": "Support", "desc": "Consulting from the best specialists" },
            "web": { "title": "WEB", "desc": "Create your own web page with online appointments" },
            "results": { "title": "Results", "desc": "Professional certificates" }
        },
        "support": {
            "question": "Do you need some help?",
            "desc": "We are always ready to help you and answer any question",
            "btn": "Contact us"
        },
        "courses": {
            "title": "Our courses",
            "left": "Cosmetology",
            "right": "Aesthetic medicine",
            "btn": "See all courses",
            "massage": "Massage",
        },
        "author": {
            "title": "About the author",
            "btn": "View full story"
        },
        "offers": {
            "title": {
                "txt": "Special",
                "color": "offers"
            }
        }
    },
    "login":{
        "title": "Login",
        "name": "Login",
        "password": "password",
        "newpass": "New password",
        "confirmPas": "Confirm password",
        "login": "Sign in",
        "registration": "Sign up",
        "recoveryPass": "Recovery",
        "btnRecover": "Recover",
        "save": "Save",
        "generate": "Generate",
        "badDate": "Invalid data",
        "password2": "Password must be 6 characters long",
        "forgotPass": "Forgot your password ?",
        "message": "Please check your mailbox"
    },
    "registration":{
        "title": "Registration",
        "name": "Name",
        "surname": "Surname",
        "phone": "Phone",
        "email": "email",
        "password": "Password",
        "password2": "Confirm password",
        "proffession": {
            "title":"Profession",
            "cosmetolog": "Esthetician (nurse)",
            "medic": "Doctor",
            "other": "For clients without an esthetician certificate"
        },
        "errors": {
            "password1" : "Password must be 6 characters long",
            "password2" : "Password mismatch"

        },  
        "strongPassword": "Parola nu este sigură",
        "messageCertificate": "The first and last name will appear on the certificate",
        "messageBusyUser":"email este ocupat",
        "certificate": "Upload Certificate",
        "login": "Login",
        "log_in": "SIGN UP",
        "politics": "Accept terms and conditions",
        "politics2": "I accept the privacy policy",
        "popup": "Your registration is being verified",
        "country": "Country",
        "modal": {
            "message": `Now you are registered! Congratulations! <br/> 
            Your account will be active within 24 hours. <br/>
            As a show of our appreciation, we’re giving you one video lesson from our courses! <br/>
            To begin the see video, access your personal account  <a href='https://lica.doctor/ru/dashboard'>https://lica.doctor/dashboard<a/> <br/>
            We are looking forward to seeing you among our students! <br/> 
            For any inquiries please contact our managers: info@lica.doctor `,
          "message2": `Now you are registered! Congratulations! <br/>
            Your account will be active within 24 hours. <br/>
            We are looking forward to seeing you among our students! <br/>
            For any enquiries please contact our managers: info@lica.doctor.`
        }
    },
    "shop":{
        "links": {
            "home": "Home",
            "shop": "Shop"
        },
        "title": "Shop",
        "filtreTitle": "Product filter",
        "brand": "Brand",
        "price": "Price",
        "categorie": "Category",
        "sorted": "Range",
        "result": "results",
        "btnAdd": "Add to",
        "loadPage": "Load more",
        "specialist": "Specialists"
    },
    "specialists":{
        "linkHome": "Acasa",
        "title":"Specialists",
        "btn": "make an appointment",
        "btn2": "Shop",
        "selectTitle": "Choose a date to sign up",
        "price": "Consultation from",
        "reload": "Load more",
        "result": "results",
        "search": "Search",
        "address": "Address",
        "certificateTitle": {
            "text": "Certificates and ",
            "color": "diplomas"
        }
    },
    "certificates":{
        "title": "Lica.Doctor Beauty Academy Certificates ",
        "descTitle": "Certificates received after viewing the courses on the platform Lica.Doctor Beauty Academy",
        "title2": "My certificates",
        "descTitle2": "Personal certificates and diplomas , which do not refer to the academy",
        "btnAdd": "Add certificate",
        "messageCertificate": "You don't have any certificates yet",
        "messageCertificateLink": "Go to the courses page",
        "warning": "Warning",
        "confirmation": "Remove Certificate ?",
        "delete": "Remove"

    },
    "changePass":{
        "mainTitle": "Change password",
        "oldPass": "Old password",
        "newPass": "New password",
        "repeatPass": "Repeat password",
        "save": "Save",
        "errMes1": "The password doesn't match"
    },
    "courses":{
        "noAvailable": "You don't have any courses yet, you can",
        "noAvailableLink": "buy the first one.",
        "free": "Free",
        "advantage": "Bring your ideas to reality",
        "price": "0 MDL",
        "perMonth": "/month",
        "current": "Current plan",
        "advantage1" : "Video viewing access 10 hours / month",
        "advantage2": "Limited access to courses",
        "advantage3": "Schedule of lessons and courses",
        "type": "Pro",
        "advantage4": "Best for individuals",
        "price2": "300 MDL",
        "buy": "Buy Pro",
        "advantage5": "Video viewing 36 hours / month",
        "advantage6": "Unlimited access to courses",
        "work": "Working materials",
        "resource": "Resources",
        "type2": "Pro+",
        "advantage8": "For true professionals",
        "price3": "1 200 MDL",
        "buy2": "Buy Pro+",
        "advantage10": "Unlimited video viewing access",
        "advice": "Practical advice from curators"
    },
    "myPage":{
        "message": "Fill the field",
        "title": "My page",
        "certificates": "My certificates",
        "certificatesDesc": "List of displayed certificates",
        "services": "My services",
        "btnAdd": "Add",
        "btnSave": "Save",
        "personalLink": "Personal link",
        "placeholder":{
            "name": "Name / Surname",
            "speciality": "Speciality",
            "price": "Price",
            "address": "Address"
        }
    },
    "orders":{
        "title": "History",
        "titleDesc": "All operations",
        "message": "You do not have any payments yet, a list of your payments will be displayed here"
    },
    "protocols":{
        "message": "You don't have any protocols yet, you can get the protocols when you purchase the course"
    },
    "requests":{
        "busy": "Busy",
        "daysOfWeek": ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]
    },
    "article":{
        "home": "Home",
        "blog": "Blog",
        "article": "Article",
        "back": "back",
        "readMore": "Read more"
    },
    "checkout":{
        "message": "Your order has been successfully completed!",
        "messageDesc": "Soon you will receive an email with the details of your order"
    },
    "product":{
        "home": "Home",
        "shop": "Shop",
        "add": "Add to cart",
        "added": "Added",
        "sliderTitle": ["Similar","products"],
        "returnPolicy": "Return: Cosmetics with sealed packaging and / or foil may only be returned if the packaging and / or foil are intact.",
        "inStock": "Not in stock"
    },
    "promotion":{
        "home": "Home",    
        "breadcrumb1": "Promotions",
        "breadcrumb2": "Promotion",
        "back": "Back"
    },
    "promotions":{
        "readMore": "Read more",
        "home":"Home",
        "breadcrumb1":"Promotions"
    },
    "usefulInfo":{
        "home": "Home",
        "blog": "Useful Info",
        "article": "Article",
        "back": "back"
    },
    "appoitment":{
        "title": "Sign up",
        "namePhone": "Date and time",
        "comment": "Comment",
        "name": "Name",
        "phone": "Phone",
        "btnModal": "Send",
        "message": "Your application has been sent!"
    },
    "calendar":{
        "title": "Edit the request",
        "namePhone": "Name and phone number",
        "name": "Name",
        "phone": "Phone",
        "timeDate": "Date and time",
        "comment": "Comment",
        "btn": "Save"
    },
    "confirmation": {
        "text": "Successful save"
    },
    "politics":{
        "message": "We use cookies on our website in order to provide the best way to tailor your preferences. By clicking on the ”I Agree” button you agree to the use of cookies. Go to Cookie Settings to find out more",
        "btn": "I Agree",
        "link": "Security policy"
    },
    "productSelect": "Search Product",
    "certificateUpload": "This certificate confirms your qualifications",
    "warnings": "Warnings",
    "breadCrumbs": {
      "back": "Back"
    },
    coursesUpdatesModal: {
        title: "You have new videos/protocols in the courses",
        content: "A new video, protocol has been added to the course <b>{courses}</b>",
        button: "Go to courses",
    }
}
