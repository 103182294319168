<template>
  <div class="dashboard__col dashboard_my_page">
    <input type="text" :value="`https://lica.doctor/${langStorage}/expert/${storeUser.userID}`" id="specialistLinkCopy">
    <!-- <h1>{{$t('myPage.title')}}</h1> -->
    <div class="orders__col">
      <div class="wrapp_container">
        <div class="expert_link_row" v-if="storeUser.profile.profile.landing_page_is_active">
          <span>{{$t('myPage.personalLink')}}</span>
          <div class="expert_link" @click="copySpecialistLink" @dblclick="goToExpertLink">
            <span>lica.doctor/expert/{{storeUser.userID}}</span>
            <svg width="15px" height="15px" class="iocn-copy">
              <use xlink:href="/icons.svg#copy"></use>
            </svg>
          </div>
        </div>
        <div class="user_data">
          <div class="row dash_header">
            <div class="avatar" @click="$refs.dashboardAvatar.click()">
              <img :src="user.image" alt="" > 
              <input @change="setAvatarPhoto" ref="dashboardAvatar" type="file" v-show="false" accept="image/*">
            </div>
            <div class="right">
              <input class="name" type="text" :placeholder="$t('myPage.placeholder.name')" v-model="user.name">
              <input class="specialty" type="text" :placeholder="$t('myPage.placeholder.speciality')" v-model="user.specialization">
              <div class="wrapp_price">
                <div class="price">
                  <input class="price_data" type="text" :placeholder="$t('myPage.placeholder.price')" v-model="user.price">
                  <select v-model="user.money_type" class="currency">
                    <option value="EUR">&#8364;</option>
                    <option value="USD">&#36;</option>
                    <option value="MDL">MDL</option>
                    <option value="RUB">&#8381;</option> 
                  </select>
                </div>
                <input class="location" type="text" :placeholder="$t('myPage.placeholder.address')" v-model="user.address">
              </div>
            </div>
          </div>
          <div class="row desc" style="margin-top:20px">
            <!-- <textarea placeholder="Descriptions" class="dash_desc" v-model="user.desc"></textarea> -->
            <!-- <tiptap class="dash_desc" placeholder="Descriptions" v-model="user.desc"/> -->
             <ckeditor :editor="editor" v-model="user.desc" :config="editorConfig"></ckeditor>
          </div>
        </div>
        <div class="user_certificate" v-if="true">
          <div class="certificates__col">
            <div class="own">
              <strong>
                {{$t('myPage.certificates')}}
              </strong>
              <div class="subtitle">
                {{$t('myPage.certificatesDesc')}}
              </div>
       
              <div class="row">             
                <div class="card" v-for="certificate in user.certificates" :key="certificate.id" @click="certificate.show = !certificate.show">
                    <img v-if="!checkPdfLink(certificate.image)" :src="certificate.image" alt="" :style="{'filter': certificate.show ? 'unset': 'brightness(0.5)'}">
                    <!-- <img v-else src="/img/diploma3.jpg" alt="" :style="{'filter': certificate.show ? 'unset': 'brightness(0.5)'}"> -->
                    <div class="pdf_object" :style="{'filter': certificate.show ? 'unset': 'brightness(0.5)'}" v-else>
                      <iframe 
                        :src="certificate.image + '#toolbar=0&navpanes=0'"
                        width="250px" 
                        height="185px"
                      />
                    </div>
                  <svg class="icon-eye" :style="{'display': certificate.show ? 'none': 'block'}">
                    <use xlink:href="/icons.svg#eye"></use>
                  </svg>
                  <span>{{ certificate[`title_${langStorage}`] ? certificate[`title_${langStorage}`] : titleLink(certificate.image)}}</span>
                </div>              
              </div>
            </div>
          </div>
        </div>
       
        <div class="wrapp_btn" style="margin-top:50px">
           <h2 v-if="user.work_slider.length">{{$t('myPage.services')}}</h2>
          <button @click="addWork" class="add-work">{{$t('myPage.btnAdd')}}</button>
        </div>
        <div class="user_work_slider">

          <VueSlickCarousel v-if="user.work_slider.length" :arrows="true" :dots="true" :slidesToShow="3" :touchMove="touchMoveCarousel" :responsive="responsiveWork">
            <div v-for="(item, ind) in user.work_slider" :key="item.id">
              <div @click="openWork(ind)" class="item_work">
                <div class="wrapp_avatar">
                  <img class="avatar" :src="item.image" alt="">
                  <img class="settings_icon" src="/img/settings.svg" alt="">
                </div>
                <h3 class="title">{{ item.title }}</h3>
                <p class="desc inner-p" v-html="item.description"></p>
              </div>
            </div>
          </VueSlickCarousel>

          <div class="row btn">
            <button class="save" @click="saveUser($event)">{{$t('myPage.btnSave')}}</button>
          </div>
        </div>
      </div>
    </div>

    <transition v-if="workModal" name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header" @click="$refs.modalFileAvatar.click()">
              <img class="modal-avatar" :src="workObj.image" alt="">
              <input @change="changeImageWork" ref="modalFileAvatar" v-show="false" type="file" accept="image/*">
            </div>

            <div class="modal-body">
              <div class="alert__block" v-show="required">
              <span class="alert__message">{{$t('myPage.message')}}</span>
              </div>
              <input required v-model="workObj.title" class="title" type="text" placeholder="Title work" style="margin-bottom: 20px">
              <!-- <textarea v-model="workObj.desc" class="desc" placeholder="Description work"></textarea> -->
              <!-- <tiptap v-model="workObj.desc" placeholder="Title work" class="desc"/> -->
                <ckeditor  :editor="editor" v-model="workObj.desc" :config="editorConfig"></ckeditor>
            </div>

            <div class="modal-footer">
              <button class="close" @click="workClose">Close</button>
              <button class="delete" @click="workDelete">Delete</button>
              <button class="save"  @click="workSave">Save</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <NotificationSuccenss style="display:none" ref="not_success" @close="closeSuccenss" class="notification_popup"/>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Tiptap from '@/components/Tiptap.vue'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import NotificationSuccenss from "@/components/NotificationSuccess.vue";
import _ from 'lodash';

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import { mapGetters, mapActions } from "vuex"

const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    VueSlickCarousel,
    Tiptap,
    ckeditor: CKEditor.component,
    NotificationSuccenss
  },
   beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.checkList().then((status)=> {
        if(!status){
          vm.$router.push({name: 'Home', params: {lang: vm.langStorage}});
        }
      });
    });
  },
  beforeMount(){
   this.getUser();
  },
  data(){
    return {
      required: false,
      workModal: false,
      user: {
        profileId: '',
        name: '',
        desc: '',
        image: '',
        specialization: '',
        price: '',
        address: '',
        certificates: [],
        work_slider: []
      },
      newImage: '',
      newSliderWork: [],
      workObj: {
        id: '',
        ind: '',
        image: '/img/addImage.png',
        file: '',
        title: '',
        desc: ''
      },
      
      responsiveWork: [
        {
          breakpoint: 430,
          settings: {
            slidesToShow: 1
          }
        }
      ],
     
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
              'heading',
              'bold',
              'italic',
              'link',
              'bulletedList', 
              'numberedList',
              'undo',
              'redo'
          ]
        }
      },
    }
  },
  watch: {
    langStorage(){
      this.getUser();
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      storeUser: 'user/user'
    }),
    touchMoveCarousel(){
      return window.innerWidth > 430 ? false : true;
    },
    hostName(){
      // return window.location.hostname;
      return window.location.origin;
    }
  },
  methods: {

    ...mapActions({
      editUser: 'user/user',
    }),
    changeImageWork(e){
      this.workObj.file = e.target.files[0];

      let reader = new FileReader();
      
      reader.addEventListener("load", function () {
        this.workObj.image = reader.result;
      }.bind(this), false);

      if(this.workObj.file){
        reader.readAsDataURL(this.workObj.file);
      }
    },
    addWork(){
      this.workModal = true;
    },
    openWork(ind){
      this.workObj.id = this.user.work_slider[ind].id;
      this.workObj.ind = ind;
      this.workObj.image = this.user.work_slider[ind].image;
      this.workObj.title = this.user.work_slider[ind].title;
      this.workObj.desc = this.user.work_slider[ind].description;
      this.workModal = true;
    },
    workClose(){
      this.workObj.id = ''
      this.workObj.ind = ''
      this.workObj.image = '/img/addImage.png'
      this.workObj.file = ''
      this.workObj.title = ''
      this.workObj.desc = ''
      this.workModal = false;
    },
    async workSave(){
      if(this.workObj.id != ''){
        let formDate = new FormData();                
        formDate.append('title', this.workObj.title);
        formDate.append('description',this.workObj.desc);
        if(this.workObj.file){ 
          formDate.append('image', this.workObj.file)
        }           
        let req = await fetch(`${domain}/accounts/slide_after_before/${this.workObj.id}/`, {
          method: 'PATCH',
          body: formDate
        });
        if(req.ok){
          this.successModal();
          this.$store.dispatch('specialists/setSpecialists');
        }
        this.getUser();
        this.workModal = false;

        this.workObj.id = ''
        this.workObj.ind = ''
        this.workObj.image = '/img/addImage.png'
        this.workObj.file = ''
        this.workObj.title = ''
        this.workObj.desc = ''
      }
      else if (this.workObj.title != '' && this.workObj.desc != '' && this.workObj.file != '') {
        let formDate = new FormData();        
        formDate.append('user', this.user.profileId);           
        formDate.append('title', this.workObj.title);           
        formDate.append('description', this.workObj.desc);           
        formDate.append('image', this.workObj.file);           
        const req = await fetch(`${domain}/accounts/slide_after_before/`, {
          method: 'POST',
          body: formDate
        });
        if(req.ok) {
          this.successModal();
          this.$store.dispatch('specialists/setSpecialists');
          if(this.user.work_slider.length == 3){
            location.reload();
          }
        }
        this.getUser();
        this.workModal = false;

        this.workObj.id = ''
        this.workObj.ind = ''
        this.workObj.image = '/img/addImage.png'
        this.workObj.file = ''
        this.workObj.title = ''
        this.workObj.desc = ''

        this.required = false;
      }
      else if(this.workObj.title == ''){
        this.required = !this.required
        // window.setTimeout(this.required = false, 2000)
      }

    },
    async workDelete(){
      if(this.workObj.id != ''){

        let req = await fetch(`${domain}/accounts/slide_after_before/${this.workObj.id}/`, {
          method: 'DELETE',
        });
        if(req.ok){
          this.user.work_slider.splice(this.workObj.ind, 1);
          this.$store.dispatch('specialists/setSpecialists');
          this.successModal();
        }
        this.workModal = false;

        this.workObj.id = ''
        this.workObj.ind = ''
        this.workObj.image = '/img/addImage.png'
        this.workObj.file = ''
        this.workObj.title = ''
        this.workObj.desc = ''
      }
    },
    async getUser(){
      try {
        const response = await fetch(`${domain}/${this.langStorage}/accounts/users/${this.storeUser.userID}/`);
        const data = await response.json();
        this.editUser({name: 'profile', value: data});
        this.user.profileId = data.profile.id;
        this.user.name = data.profile.name;
        this.user.desc = data.profile.description;
        this.user.image = data.profile.image ? data.profile.image : '/img/avatar-dashboard.png';
        this.user.specialization = data.profile.specialization;
        this.user.price = data.profile.consultation_price;
        this.user.money_type = data.profile.currency.toUpperCase();
        this.user.address = data.profile.address;
        this.user.certificates = _.cloneDeep(data.profile.certificates_and_diplomas);
        this.user.work_slider = data.profile.user_page_slide;
      }
      catch(er){
        console.error(er);
      }
    },
    async saveUser(e){
      e.target.classList.add('active');
      setTimeout(() => {
        e.target.classList.remove('active')
      }, 2500);


      try {
        let formData = new FormData();
        formData.append('name', this.user.name);
        formData.append('description', this.user.desc);
        if(this.newImage != '') {
          formData.append('image', this.newImage);
        }
        formData.append('specialization', this.user.specialization);
        formData.append('consultation_price', this.user.price);
        formData.append('currency', this.user.money_type.toLowerCase());
        formData.append('address', this.user.address);

        //Edit User

        let request = await fetch(`${domain}/${this.langStorage}/accounts/user_profile/${this.user.profileId}/`, {
          method: 'PATCH',
          body: formData
        });
       
        //PATCH Certificate 
        for (const el of this.user.certificates) {
          fetch(`${domain}/accounts/certificates_and_diplomas/${el.id}/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              show: el.show
            })
          });
        }
        if(request.ok){
          this.successModal();
        }
        this.$store.dispatch('specialists/setSpecialists');
        this.$store.dispatch('user/user', {'name': 'userName', 'value': this.user.name});
      }
      catch(er){
        console.error(er);
      }
    },
    successModal(){
      this.$refs.not_success.$el.style.display = "block";
      setTimeout(() => this.$refs.not_success.$el.style.opacity = "1", 300);
      setTimeout(() => {
        this.$refs.not_success.$el.style.opacity = "0"
      }, 2500);
      setTimeout(() => {
        this.$refs.not_success.$el.style.display = "none";
      }, 3000);
    },
    setAvatarPhoto(e){
      this.newImage = e.target.files[0]; 
    },
    async checkList() {
      try {
        // const response = await fetch(`${domain}/${this.langStorage}/courses/access_list/`, { 
        //   headers: { 
        //     Authorization: `Token ${this.storeUser.token}`
        //   } 
        // });
        // if(!response.ok){
        //   throw response;
        // }
        // const data = await response.json();

        return true;
      }
      catch(er){
        console.error(er);
      }

    },
    copySpecialistLink(e){ 
      let el = e.target.closest(".expert_link");
      el.classList.add('active');
      let copyText = document.getElementById("specialistLinkCopy");
      copyText.select();
      document.execCommand("copy");
      setTimeout(() => {
        el.classList.remove('active');
      }, 1000)
    },
    goToExpertLink(){
      window.open(`${this.hostName}/${this.langStorage}/expert/${this.storeUser.userID}/`);
    },
    checkPdfLink(str){
      return str.substring(str.length - 3).toUpperCase() == "PDF";
    },
    titleLink(str){
      let arr =  decodeURI(str).split('/');
      arr = arr[arr.length - 1].split('_');
      return arr[0];
    },
    closeSuccenss(){
      this.$refs.not_success.$el.style.opacity = "0"
      setTimeout(() => this.$refs.not_success.$el.style.display = "none", 1000);
    }
  }
}
</script>

<style lang="scss">
.night { 
  .expert_link_row {
    color: white;
  }
}
.alert__block{
  border-radius: 5px;
  margin-bottom: 1em;
  height: 30px;
  align-items: center;
  display: flex;
}
.alert__message{
  color: red;
}
.dashboard_my_page {
  margin-top: 16px;
  .wrapp_container {
    flex-direction: column;
    .row.dash_header {
      display: flex;
    }
    .right {
      width: 100%;
      padding-left: 40px;
    }
    .clear-btn {
      height: 40px;
    }
    .vue__time-picker .dropdown ul li:not([disabled]).active {
      background-color: #DC3E6C;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
     cursor: pointer;
      width: 140px;
      min-width: 140px;
      min-height: 140px;
      &:hover {
        img {
          filter: brightness(0.5);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: 0.3s;
        border-radius: 50%;
      }
    }
    .user_data {
      width: 100%;
      margin-top: 50px;
      max-width: 800px;

      input {
        height: 35px;
        outline: none;
        border: 1px solid #BAC3C9;
        border-radius: 5px;
        padding-left: 10px;
        margin-bottom: 10px;
      }
      .name {
        width: 100%;
      }
      .specialty {
        width: 70%;
      }
      .wrapp_price {
        display: flex;
        justify-content: space-between;
        .location {
          width: 77%;
        }
      }
      .price {
        position: relative;
        width: 20%;
        .price_data {
          width: 100%;
          padding-right: 30px;
        }
        .currency {
          position: absolute;
          width: 33px;
          right: 1px;
          bottom: 16px;
          border: none;
          background-color: transparent;
          outline: none;
        }
      }
      .schedule {
        display: flex;
        justify-content: space-between;
        input {
          border: none;
          margin: 0;
          height: 33px;
        }
        .vue__time-picker.time-picker {
          border: 1px solid #BAC3C9;
          border-radius: 5px;
          width: 49%;
          height: 35px;
          input {
            width: 100%;
          }
        }
      }
      .dash_desc {
        width: 100%;
        height: 100px;
        margin-top: 20px;
        padding-left: 10px;
        border: 1px solid #BAC3C9;
        border-radius: 5px;
        outline: none;
        resize: none;
        .ProseMirror {
          height: 100%;
          overflow: auto;
        }
      }
    }
    .user_certificate {
      .certificates__col strong,.certificates__col .subtitle {
        width: 100%;
        text-align: center;
      }
      .certificates__col .row {
        display: flex;
        justify-content: center;
      }
      .row {
        display: flex;
        align-items: center;
        .card {
          width: 250px;
          height: 250px;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          border-radius: 10px;
          .pdf_object {
            pointer-events: none;
            overflow: hidden;
          }
          img,.pdf_object {
            width: 250px;
            height: 185px;
            object-fit: cover;
            filter: brightness(0.5);
            border-radius: 10px;
          }
          .icon-eye {
            position: absolute;
            right: 10px;
            width: 50px;
            height: 50px;
            fill: #991484;
          }
          span {
            width: 100%;
            margin-top: 10px;
            padding-left: 5px;
            height: 55px;
          }
        }
      }
    }
    .user_work_slider {
      width: 100%;
      max-width: 800px;
      border-top: 1px solid #BAC3C9;
      padding-top: 50px;
      .slick-prev:before, .slick-next:before {
        color: darkgrey;
      }
      .item_work {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        
        &:hover {
          .avatar {
            filter: brightness(0.5);
          }
          .wrapp_avatar .settings_icon {
            opacity: 1;
          }
        }
        .avatar {
          width: 150px;
          height: 150px;
          border-radius: 10px;
          transition: 0.3s;
          
        }
        .title {
          max-width: 300px;
        }
        .desc {
          margin: 0;
          max-width: 300px;
          height: 93px;
          text-align: center;
          font-size: 12px;
          overflow-y: hidden;
        }
        .wrapp_avatar {
          position: relative;
          .settings_icon {
            position: absolute;
            opacity: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            transition: 0.3s;
          }
        }
      }

      .row.btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
        button {
          border: none;
          outline: none;
          border-radius: 5px;
          padding: 18px;
          color: white;
          cursor: pointer;
          font-size: 18px;
          transition: 0.4s;
          &:active {
            transform: rotate(10deg);
          }
        }
      .save {
        background-color: #14685D;
        &:hover {
          background-color: #03b19a;
        }

      }
      .active {
        background-color: #930f81 !important;
      }

      }
    }
  }

  .modal-mask {
    .modal-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .modal-header:hover {
      cursor: pointer;
      .modal-avatar {
        filter: drop-shadow(2px 4px 6px black);
      }
    }
    .modal-avatar {
      width: 150px;
      height: 150px;
      border-radius: 10px;
      transition: 0.3s;
      object-fit: cover;
      object-position: center;
    }
    .modal-body {
      width: 100%;
      max-width: 300px;
      .title, .desc {
        width: 100%;
        height: 35px;
        border: 1px solid #BAC3C9;
        border-radius: 5px;
        padding-left: 10px;
        outline: none;
      }
      .desc {
        margin-top: 20px;
        height: 100px;
        resize: none;
        .ProseMirror {
          height: 100%;
          overflow: auto;
        }
      }
    }
    .modal-footer {
      button {
        border: none;
        outline: none;
        border-radius: 5px;
        padding: 10px 15px;
        color: white;
        cursor: pointer;
        
      }
      .close {
        background-color: rgba(0, 89, 255, 0.74);
      }
      .save {
        background-color: #14685D; 
      }
      .delete {
        background-color: rgba(255, 0, 0, 0.589);   
        margin: 0 10px;
      }
    }
  }

  .expert_link_row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .expert_link {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 10px 0 10px;
      font-size: 13px;
      width: 100%;
      max-width: 350px;
      height: 40px;
      border-radius: 5px;
      border: 1px #cccccc solid;
      transition: 0.5s;
      &:hover {
        background-color: #89227f42;
        .iocn-copy {
          opacity: 1;
        }
      }
      .iocn-copy {
        position: absolute;
        right: 20px;
        transition: 0.5s;
        opacity: 0;
      }
      &.active {
        background-color: rgba(0, 128, 0, 0.363);
      }
    }
   
  }

  #specialistLinkCopy {
    position: absolute;
    left: -500000000px;
  }
  .wrapp_btn {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-bottom: 15px;
    @media(max-width: 768px) {
      flex-direction: column;
    }
    h2 {
      margin-left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
    }
    .add-work {
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 15px 20px;
      color: white;
      cursor: pointer;
      font-size: 18px;
      transition: 0.4s;
      background-color: #0066FF;
      margin-left: auto;
      &:hover {
        background-color: #00aefff3;
      }
    }
  }
  

  @media(max-width: 768px) {
    .wrapp_container .row.dash_header {
      align-items: center;
      flex-direction: column;
    }
    .wrapp_container .user_data, .wrapp_container .user_data .dash_desc {
      margin-top: 0;
    }
    .wrapp_container .avatar {
      margin-bottom: 20px;
    }
    .wrapp_container .right {
      padding-left: 0;
    }
    .wrapp_container .user_data .specialty,.wrapp_container .user_certificate .certificates__col .own .card, .wrapp_container .user_certificate .certificates__col .own .add__certificate {
      width: 100%;
    }
    .wrapp_container .user_data .price {
      width: 35%;
    }
    .wrapp_container .user_data .wrapp_price .location {
      width: 60%;
    }
    .wrapp_container .user_certificate .certificates__col .row {
      flex-direction: column;
    }
    .wrapp_container .user_certificate .certificates__col .own .card .img__cont, .wrapp_container .user_certificate .certificates__col .own .add__certificate {
      height: 200px !important;
    }
    .wrapp_container .user_certificate .certificates__col .own .card .img__cont img {
      filter: brightness(0.5);
    }
    .wrapp_container .user_certificate .certificates__col .own .card .close {
      display: block;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
  .ck-editor__main {
    .ck-content {
      // height: 150px;
      max-height: 150px;
      &::-webkit-scrollbar {
        background: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #930684;
        border-radius: 10px;
      }
    }
  }
}






@media(max-width:1200px) and (min-width: 430px) {
  .dashboard__col .orders__col .order .order__num {
    margin-right: 100px !important;
  }
}
</style>